import React from 'react'
import styled from 'styled-components'
import Default from '../components/Layouts/Default';

const docUrl = "https://docs.google.com/document/d/e/2PACX-1vQKOekCPHea00uwkbTt1SVxNV1I6MIGtJJ-JYy2cO8MQHAKIdv8mJA7Hxsv3rEpfpkPC5nxIt1_pw7P/pub?embedded=true";

const TermsFrame = styled.iframe`
  width: 100%;
  height: 100vh;
  width: 100%;
  border: none;
`

const PrivacyPage = () => <Default><TermsFrame src={docUrl} /></Default>


export default PrivacyPage;
